import { useEffect, useState } from "react";

const Loader = props => {
    const [show, setShow] = useState(props?.delayed === true ? true : false);
    const [showTimer, setShowTimer] = useState(-1);

    console.log("[Loader]", props?.delayed, props?.delay, props?.className);

    useEffect(() => {
        if (props?.delayed && showTimer === -1) {
            console.log("[Loader] Loader delayed");
            const timer = setTimeout(() => {
                console.log("[Loader] Showing loader");
                setShow(true);
            }, props.delay || 250);
            setShowTimer(timer);
        }

        return () => {
            if (showTimer !== -1) {
                console.log("[Loader] Clearing timer");
                clearTimeout(showTimer);
            }
        }
    } , [props.delayed, props.delay,showTimer]);

    let circleCommonClasses = 'h-5 w-5 bg-current rounded-full';

    console.log("[Loader] Show loader: ", show);
    return (
   <div className={'flex transition-opacity ease-in duration-700 ' + props?.className + (show ? ' opacity-100' : ' opacity-0')} alt="Inhalte werden geladen..." title="Inhalte werden geladen...">
        <div className={`${circleCommonClasses} animate-bounce mr-1`}></div>
        <div className={`${circleCommonClasses} animate-bounce200 mr-1`}></div>
        <div className={`${circleCommonClasses} animate-bounce400`}></div>
   </div>
    );
};

export default Loader;