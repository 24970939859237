import NewsPanel from "./NewsPanel";

export default function Home() {
    return (
      <>
        <section id="about" className="bg-gray-900 py-20">
          <div className="max-w-5xl px-6 mx-auto text-center">
            <p className="text-gray-300 text-2xl">
              Willkommen auf der offiziellen Seite von hyJax
            </p>
            <p className="text-gray-300 mt-4">
              Wir sind eine kleine, ambitionierte Gruppe von Gamern und suchen
              immer nach neuen Bekanntschaften und Unterhaltungsmöglichkeiten.
            </p>
            <p className="text-gray-300 mt-4">
              Zu unseren meist gespielten Spielen gehören unter anderem
              Counter-Strike: Global Offensiv, League of Legends und ARK:
              Survival Evolved. Für das letzte werden wir auch einige Servern
              mit unterschiedlichen DLCs hosten und für euch zugänglich machen.
            </p>
            <p className="text-gray-300 mt-4">
              Bei uns wird Spaß großgeschrieben, deshalb laden wir Dich
              herzlichst dazu ein, uns auf unserem TeamSpeak- oder
              Discord-Server zu besuchen! Ob du nach einem Platz zum unterhalten
              mit deinen Freunden suchst, oder dich eventuell einer bestehenden
              Gruppe an Leuten anschließen willst, bei uns kannst du fündig
              werden.
            </p>
            <p className="text-gray-300 mt-4">
              Solltest du Ideen für uns haben, ein Projekt Planen, bei dem wir
              dir eventuell helfen können oder einfach nur Feedback geben
              willst, kannst du dich gerne bei einem unserer Admins oder
              Moderatoren im TeamSpeak/Discord, in der Steam-Gruppe oder aber
              auch unter der E-Mail <a href="mailto:support@hyjax.de">support@hyjax.de</a> melden.
            </p>
            <p className="text-gray-300 mt-4">
              Wenn du dich mehr über die Leute hinter hyJax interessierst, oder
              wissen möchtest, wie hyJax zu Stande kam, dann schau dich doch ein
              wenig auf unserer Seite um. Wir sind sicher, du wirst etwas
              Interessantes finden!
            </p>
          </div>
        </section>
        
        <NewsPanel/>
        </>
    );
}