import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import Logo from "./assets/hyjaxde_logo.svg";
import Steam from "./assets/logos/steam";
import TeamSpeakLogo from "./assets/logos/teamspeak";
import "./index.css";
import Faq from "./views/Faq/Index";
import Home from "./views/Home";
import Members from "./views/Members";
import Member from "./views/Members/Member";
import News from "./views/News";
import Servers from "./views/Server";

function App() {
  return (
    <div className="flex flex-col h-screen">
      <div className="flex-grow bg-gray-900">
        <header className="shadow bg-gray-800">
          <div className="container mx-auto px-8 py-5">
            <div className="flex items-center flex-col md:flex-row justify-between">
              <div>
                <Link to="/">
                  <img
                    src={Logo}
                    className="w-full max-h-10"
                    alt="HyJax Logo"
                  />
                </Link>
              </div>

              <div className="flex items-center flex-col md:flex-row gap-0 md:gap-4 text-xl antialiased">
                <Link to="/news" className="p-2 hover:text-red-500">
                  Neuigkeiten
                </Link>
                <Link to="/server" className="p-2 hover:text-red-500">
                  Server
                </Link>
                <Link to="/members" className="p-2 hover:text-red-500">
                  Mitglieder
                </Link>
              </div>

              <div className="flex items-center -mx-0 md:-mx-2 flex-wrap gap-2 justify-center">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="flex min-h-32 min-w-32 items-center mx-2 text-gray-100 hover:text-red-500"
                  href="https://steamcommunity.com/groups/hyjaxde"
                  title="Unsere Steam-Gruppe"
                >
                  <div className="flex gap-2 text-xl">
                    <Steam className="nav-website-icon" alt="Steam Logo" />
                  </div>
                </a>
                <a
                  className="flex min-h-32 min-w-32 items-center mx-2 text-gray-100 hover:text-red-500"
                  href="ts3server://hyjax.de?port=9990"
                  title="Unser Teamspeak Server"
                >
                  <div className="flex gap-2 text-xl">
                    <TeamSpeakLogo
                      className="nav-website-icon"
                      alt="Teamspeak Logo"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </header>

        <Routes> 
          <Route path="/" element={<Home />} />
          <Route path="news" element={<News />} />
          <Route path="server" element={<Servers />} />
          <Route path="members/:id" element={<Member />} />
          <Route path="members" element={<Members />} />
          <Route path="faq" element={<Faq />} />
        </Routes>
        
      </div>
      <footer className="bg-gray-800 w-full align-bottom mt-auto mb-0 bottom-0 fixed">
        <div className="container mx-auto px-6 py-4">
          <div className="flex justify-between items-center">
            <div className="text-gray-200 text-left">
              &copy; hyJax.de - made by JustPlayer
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
