import axios from "axios";
import React from "react";
//import ReactMarkdown from "react-markdown";
import { useQuery } from "react-query";
import SimpleDateTime from "react-simple-timestamp-to-date";
import Loader from "../../components/Loader";

// Old API: https://api.hyjax.de/api/collections/get/blog?limit=1&sort[_created]=-1

export default function NewsPanel() {
  const { isLoading, isError, data: news } = useQuery("news_newest", async () => {
    return await axios.get(
      `https://api.hyjax.de/api/content/items/news?limit=1&sort={"_created:":-1}`,
      {
        timeout: 10000,
      }
    );
  });

  /*
      <section id="news" className="bg-gray-900 py-4 mt-8">
      <div className="max-w-5xl mx-auto">
        <div className="flex flex-wrap justify-center">
          <div className="w-full p-4" key={news?.data[0]._id}>
            <div className="bg-gray-900">
              <div className="mt-0.5 ">
                <span className="block font-medium text-2xl leading-snug text-gray-600 text-gray-100 animate-pulse bg-gray-600 rounded md:w-2/5">
                 Dies ist ein Titel
                </span>
                <span className="block text-xl text-gray-600 leading-snug animate-pulse bg-gray-600 rounded md:w-2/6">
                 DD.MM.YYYY - HH:MM
                </span>
              </div>
              <div className="text-gray-600 mt-5 animate-pulse bg-gray-600 rounded md:w-5/6">
                Dies ist ein Inhalt
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  */
  if (isLoading && !news && !news?.data) {
    return (
      <section id="news" className="bg-gray-900 py-4 mt-8">
        <div className="max-w-5xl mx-auto">
          <div className="flex flex-wrap items-center justify-center h-32">
            <Loader delayed="true"/>
          </div>
        </div>
      </section>
    );
  }

  if (isError) {
    <section id="news" className="bg-gray-900 py-4 mt-8">
      <div className="max-w-5xl mx-auto">
        <div className="flex flex-wrap justify-center">
            <div className="text-gray-300">
              Fehler beim laden der Neuigkeiten. Bitte versuche es später noch einmal.
            </div>
        </div>
      </div>
    </section>
  }

  if (!news?.data?.length > 0) {
    return (  
      <section id="news" className="bg-gray-900 py-4 mt-8">
        <div className="max-w-5xl mx-auto">
          <div className="flex flex-wrap justify-center">
              <div className="text-gray-300">
                Es sind keine Neuigkeiten vorhanden.
              </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section id="news" className="bg-gray-900 py-4 mt-8">
      <div className="max-w-5xl mx-auto">
        <div className="flex flex-wrap justify-center">
          <div className="w-full p-4" key={news?.data[0]._id}>
            <div className="bg-gray-900">
              <div className="mt-0.5 ">
                <span className="block font-medium text-2xl leading-snug text-black text-gray-100">
                  {news?.data[0].title}
                </span>
                <span className="block text-xl text-gray-400 leading-snug">
                  <SimpleDateTime 
                    dateFormat="DMY"

                    dateSeparator="."
                    timeSeparator=":"
                  >
                    { news?.data[0]._created }
                  </SimpleDateTime>
                </span>
              </div>
              {/* This needs to be fixed somehow, maybe custom markdown stuff for cockpit? */}
              <div className="text-gray-300 mt-5"
                dangerouslySetInnerHTML={{ __html: news?.data[0].message }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
