
const urlPattern = new RegExp('^(https?|ftp)://');

/**
 * Makes sure that the given URL is either a valid HTTP(S)/FTP URL or comes from the backend. 
 * @param {string} url The url to patch
 * @returns Safe URL
 */
export default function patchUrl(url) {
    if(!urlPattern.test(url)) {
        return "https://api.hyjax.de/storage/uploads" + url;
    }

    return url;
}
