import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import Loader from "../../components/Loader";
import Server from "./components/Servers";

export default function Servers() {
  const { isLoading, isError, data: servers, error } = useQuery("servers", async () => {
    return await axios.get(
      `https://api.hyjax.de/api/content/items/Server?sort={%20Name:-1}`,
      {
        timeout: 10000,
      }
    );
  });

  if (isError) {
    console.log("[Server] Error: ", error);
    return (
      <section id="server" className="bg-gray-900 py-20">
        <div className="max-w-6xl px-6 mx-auto text-center">
          <h2 className="text-2xl font-bold text-white">Server</h2>
          <p className="text-gray-400">
            Es ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.
          </p>
        </div>
      </section>
    );
  }

  if (isLoading || !servers || !servers.data || !servers?.data?.length > 0) {
    return (
      <section id="server" className="bg-gray-900 py-20">
        <div className="max-w-6xl px-6 mx-auto text-center">
          <h2 className="text-2xl font-bold text-white">Server</h2>
        </div>
        <div className="max-w-4xl w-full my-2 mx-auto flex flex-wrap justify-center">
          <Loader delayed="true"/>
        </div>
      </section>
    );
  }
  return (
    <>
      <section id="server" className="bg-gray-900 py-20">
        <div className="max-w-6xl px-6 mx-auto text-center">
          <h2 className="text-2xl font-bold text-white">Server</h2>
          <p className="text-gray-400">
            Hier findest du eine Übersicht über die HyJax Server.
          </p>
        </div>

        <div className="max-w-4xl my-2 mx-auto flex flex-wrap gap-4">
          {servers.data.map((server) => {
            return (
              <Server
                key={server._id}
                id={server._id}
                name={server.name + " (" + server.gameserver_ip + ")"}
                image={server.logo?.path}
                desc={server.description}
              />
            );
          })}
        </div>
      </section>
    </>
  );
}
