import { Link } from "react-router-dom";
import patchUrl from "../../../utils/url";


export default function Servers(props) {

    return (
        <Link to={"#/server/" + props.id} className="flex w-full flex-col items-center bg-gray-800 rounded-lg border border-gray-700 shadow-md md:flex-row md:max-w-x hover:bg-[#462626]">
            <img
              className="aspect-square h-48 rounded-t-lg  md:rounded-none md:rounded-l-lg"
              src={patchUrl(props.image)}
              alt=""
            />
            <div className="flex flex-col w-full justify-between p-4 leading-normal">
              <h5 className="mb-2 flex justify-between text-2xl font-bold tracking-tight text-white ">
                <span>{props.name || "{NAME}"}</span>
                {/* <span className="font-normal text-sm capitalize">{props.alternativeRankDisplay || props.rank || "{RANK}"}</span> */}
              </h5>
              <p className="mb-3 font-normal text-gray-400">
                {props.desc || "Keine beschreibung vorhanden."}
              </p>
            </div>
          </Link>
    );
}