import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import patchUrl from "../../utils/url";

export default function Member() {
    let { id } = useParams();

    const { isLoading, isError, data: member, error } = useQuery("user-" + id, async () => {
        return await axios.get(
            `https://api.hyjax.de/api/content/item/mitglieder/` + id,
            {
                timeout: 10000,
            }
        );
    });

    if (isError) {
        console.log("[Member] Error: ", error);

        return (
            <section id="member" className="bg-gray-900 py-20">
                <div className="max-w-6xl px-6 mx-auto text-center">
                    <h2 className="text-2xl font-bold text-white">Mitglied</h2>
                    <p className="text-gray-400">
                        Es ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.
                    </p>
                </div>
            </section>
        );
    }

    if (isLoading || !member || !member.data) {
        return (
            <section id="member" className="bg-gray-900 py-20">
                <div className="max-w-4xl w-full my-2 mx-auto flex flex-wrap justify-center">
                    <Loader delayed="true" />
                </div>
            </section>
        );
    }

    const socialKeys = member?.data?.social ? Object.keys(member?.data?.social) : [];
    const socialMediaLinkBlacklist = [
        "discord",
        "skype",
        
    ]

    return (
        <>
            <section id="members" className="py-4 flex flex-row max-w-6xl px-6 mx-auto gap-4 ounded-lg border border-gray-700 mt-6">
                {/* Left is the image and under that image are the social media links, on the right you can see text */}
                <div className="flex flex-col">
                    <img src={patchUrl(member?.data?.avatar?.path || "https://via.placeholder.com/150")}
                        alt="Member"
                        className="w-48 h-48 border border-gray-700 border-rounded rounded" />

                    {/* Social media links */}

                    <div className="flex flex-col gap-4 mt-4 text-sm">
                        {socialKeys.map((social) => {
                            const socialTag = member?.data?.social[social];
                            if (socialTag === null || socialTag === "" || socialTag === undefined) {
                                return null;
                            }

                            if (socialMediaLinkBlacklist.includes(social)) {
                                return (
                                    <div className="flex flex-row gap-2">
                                        <span className="first-letter:capitalize">{social}: </span>
                                        <code className="mr-auto">{socialTag}</code>
                                    </div>
                                )
                            }

                            return (
                                <a key={"button_" + social} href={socialTag} target="_blank" rel="noreferrer" className=" first-letter:capitalize ">
                                    {social}
                                </a>
                            );
                        })}
                    </div>

                </div>
                <div className="flex flex-col">
                    <div dangerouslySetInnerHTML={{ __html: member?.data?.description || "Keine Beschreibung vorhanden." }}></div>
                </div>
            </section>
        </>
    );
}
