import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import Loader from "../../components/Loader";

function makeSlug(text) {
  return text.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
}

if (!window.location.origin) {
  window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
}

// check hash for a specific question
function checkHash(slug) {
  if (window.location.hash) {
    const hash = window.location.hash.substring(1);

    if (hash === slug) {
      return true;
    }
  }
  return false
}

export default function Faq() {
  //const [Faq, setFaq] = React.useState([]);
  //const [isError, setIsError] = React.useState(false);
  const { isLoading, isError, data: Faq, error } = useQuery("faq", async () => {
    return await axios.get(
      `https://api.hyjax.de/api/content/items/faq?sort={%22_created%22:1}`,
      {
        timeout: 10000,
      }
    );
  });

  // Scroll to hash if it exists
  React.useEffect(() => {
    if (window.location.hash) {
      const hash = window.location.hash.substring(1);
      const interval = setInterval(() => {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView();
        }
      }, 500);
      return () => clearInterval(interval);
    }
  }, [Faq]);

  if (isError) {
    console.log("[FAQ] Error: ", error);
    return (
      <section id="Faq" className="bg-gray-900 py-20">
        <div className="max-w-6xl px-6 mx-auto text-center">
          <h2 className="text-2xl font-bold text-white">FAQ</h2>
          <p className="text-gray-400">
            Es ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.
          </p>
        </div>
      </section>
    );
  }

  if (isLoading || !Faq || !Faq.data || !Faq?.data?.length > 0) {
    return (
      <section id="Faq" className="bg-gray-900 py-20">
        <div className="max-w-6xl px-6 mx-auto text-center">
          <h2 className="text-2xl font-bold text-white">FAQ</h2>
        </div>
        <div className="max-w-4xl w-full my-2 mx-auto flex flex-wrap justify-center">
          <Loader delayed="true" />
        </div>
      </section>
    );
  }

  return (
    <>
      <section id="Faq" className="bg-gray-900 py-20">
        <div className="max-w-6xl px-6 mx-auto text-center">
          <h2 className="text-2xl font-bold text-white">FAQ</h2>
        </div>

        {/* search for faq items */}
        <div className="max-w-7xl w-full my-2 mx-auto flex flex-wrap justify-center">
          {Faq.data.map((item, index) => {
            return (
              <div

                key={index}
                className="w-full max-w-7xl p-2"
              >
                <div className={"bg-gray-800 rounded-lg shadow-lg p-4" + (checkHash(makeSlug(item.question)) ? ' border border-red-500' : '')}>
                  <h3 className="text-xl font-bold text-white">
                    {item.question}
                    {/* copy hash link button */}
                    <button
                      className="ml-2 text-gray-400 hover:text-gray-200 focus:outline-none focus:text-gray-200 transition duration-150 ease-in-out"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${window.location.origin}/faq#${makeSlug(item.question)}`
                        );
                      }}
                    >
                      <svg

                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4 6a2 2 0 012-2h8a2 2 0 012 2v8a2 2 0 01-2 2H6a2 2 0 01-2-2V6zm2 0v8h8V6H6zm2 2h4v4H8V8z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </h3>
                  <p className="text-gray-400" dangerouslySetInnerHTML={{ __html: item.answer }}></p>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}
