import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import SimpleDateTime from "react-simple-timestamp-to-date";
import Loader from "../../components/Loader";

export default function News() {
  //const [News, setNews] = React.useState([]);
  //const [isError, setIsError] = React.useState(false);
  const { isLoading, isError, data: News, error } = useQuery("News", async () => {
    return await axios.get(
      `https://api.hyjax.de/api/content/items/news?sort={%22_created%22:1}`,
      {
        timeout: 10000,
      }
    );
  });

  if (isError) {
    console.log("[News] Error: ", error);
    return (
      <section id="News" className="bg-gray-900 py-20">
        <div className="max-w-6xl px-6 mx-auto text-center">
          <h2 className="text-2xl font-bold text-white">Mitglieder</h2>
          <p className="text-gray-400">
            Es ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.
          </p>
        </div>
      </section>
    );
  }

  if (isLoading || !News || !News.data || !News?.data?.length > 0) {
    return (
      <section id="News" className="bg-gray-900 py-20">
        <div className="max-w-6xl px-6 mx-auto text-center">
          <h2 className="text-2xl font-bold text-white">Mitglieder</h2>
        </div>
        <div className="max-w-4xl w-full my-2 mx-auto flex flex-wrap justify-center">
          <Loader delayed="true"/>
        </div>
      </section>
    );
  }
  return (
    <>
      <section id="News" className="bg-gray-900 py-20">
        <div className="max-w-6xl px-6 mx-auto text-center">
          <h2 className="text-2xl font-bold text-white">Neuigkeiten</h2>
        </div>

        <div className="max-w-7xl my-2 mx-auto flex flex-wrap gap-4">
          {News.data.map((neuigkeit) => {
            return (
              <div className="w-full p-4 bg-gray-800 rounded-lg border border-gray-700 shadow-md" key={neuigkeit?._id}>
              <div className="">
                <div className="mt-0.5 ">
                  <span className="block font-medium text-2xl leading-snug text-black text-gray-100">
                    {neuigkeit.title}
                  </span>
                  <span className="block text-xl text-gray-400 leading-snug">
                    <SimpleDateTime
                      dateFormat="DMY"
  
                      dateSeparator="."
                      timeSeparator=":"
                    >
                      { neuigkeit._created }
                    </SimpleDateTime>
                  </span>
                </div>
                {/* This needs to be fixed somehow, maybe custom markdown stuff for cockpit? */}
                <div className="text-gray-300 mt-5 p-4"
                  dangerouslySetInnerHTML={{ __html: neuigkeit.message }}
                ></div>
              </div>
            </div>
            );
          })}
        </div>
      </section>
    </>
  );
}
