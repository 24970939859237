import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import Loader from "../../components/Loader";
import Member from "./components/Member";

// Old API: https://api.hyjax.de/api/collections/get/members?sort[_o]=1
export default function Members() {
  //const [members, setMembers] = React.useState([]);
  //const [isError, setIsError] = React.useState(false);
  const { isLoading, isError, data: members, error } = useQuery("members", async () => {
    return await axios.get(
      `https://api.hyjax.de/api/content/items/mitglieder?sort={%22rank%22:1,%20Name:-1}`,
      {
        timeout: 10000,
      }
    );
  });

  if (isError) {
    console.log("[Members] Error: ", error);
    return (
      <section id="members" className="bg-gray-900 py-20">
        <div className="max-w-6xl px-6 mx-auto text-center">
          <h2 className="text-2xl font-bold text-white">Mitglieder</h2>
          <p className="text-gray-400">
            Es ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.
          </p>
        </div>
      </section>
    );
  }

  if (isLoading || !members || !members.data || !members?.data?.length > 0) {
    return (
      <section id="members" className="bg-gray-900 py-20">
        <div className="max-w-6xl px-6 mx-auto text-center">
          <h2 className="text-2xl font-bold text-white">Mitglieder</h2>
        </div>
        <div className="max-w-4xl w-full my-2 mx-auto flex flex-wrap justify-center">
          <Loader delayed="true"/>
        </div>
      </section>
    );
  }
  return (
    <>
      <section id="members" className="bg-gray-900 py-20">
        <div className="max-w-6xl px-6 mx-auto text-center">
          <h2 className="text-2xl font-bold text-white">Mitglieder</h2>
          <p className="text-gray-400">
            Hier findest du eine Übersicht über die HyJax Mitglieder.
          </p>
        </div>

        <div className="max-w-4xl my-2 mx-auto flex flex-wrap gap-4">
          {members.data.map((member) => {
            return (
              <Member
                key={member._id}
                id={member._id}
                name={member.Name + " (" + member.gamerTag + ")"}
                rank={member.rank}
                image={member.avatar?.path}
                desc={member.description}
                alternativeRankDisplay={member.alternativeRankDisplay}
              />
            );
          })}
        </div>
      </section>
    </>
  );
}
